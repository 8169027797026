import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import OmegaCategoryBtns from '../../components/preowned/OmegaCategoryBtns'

// markup
const PiagetMain = () => {
  const data = useStaticQuery(
    graphql`
      query queryPiagetMain {
        products: allStrapiProduct(
          filter: { brand: { eq: "Piaget" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Piaget Watches for Sale'}
      canonical={'/fine-watches/other-watches-brands/piaget/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Piaget Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/piaget/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-piaget-header.png"
                alt="Pre-Owned Certified Used Piaget Watches Header"
                aria-label="Used Piaget Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">Used Piaget Watches</h1>
            <h2>
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PIAGET WATCHES AT GRAY AND
              SONS
            </h2>
            <h3>
              BUY, SELL, TRADE, CONSIGN AND REPAIR USED AND PRE-OWNED PIAGET WATCHES WITH GRAY AND
              SONS JEWELERS
            </h3>
            <p>
              Looking for the perfect Piaget watch? As a luxury watch brand, Piaget has one of the
              best reputations in the competitive world of luxury watches. The brand is widely known
              for delivering the highest value in each excellent timepiece they make. Some of Piaget
              products, for example the Piaget Emperador Temple watch featuring brilliant-cut
              diamonds, baguette-cut diamonds, and a large emerald-cut diamond, are considered as
              one of the most expensive watches in the world. However, there are several more
              affordable models from Piaget, especially if you decide to go for a certified
              pre-owned used Piaget watch from a renowned and trusted luxury watch shop.
              <br />
              <br />
              <b>Buy Piaget watches for the lowest price on the market</b>
              <br />
              <br />
              Get the best certified pre-owned used Piaget watches at amazing prices available now
              at Gray and Sons Jewelers. The most wanted luxury watches for the best prices, without
              waiting lists. Check our offer now and discover our vast inventory of available Piaget
              models for men and women. Every certified pre-owned Piaget watch purchased at Gray and
              Sons Jewelers comes with a 1-year Gray and Sons Jewelers Warranty.
              <br />
              <br />
              Recommended by thousands of customers, the Gray and Sons Showroom in Bal Harbour/Miami
              offers the best-priced luxury watches, including Piaget, Breitling, Rolex, Cartier,
              Audemars Piguet, Patek Philippe, and many others. If you want to buy luxury watch and
              save some money at the same time, Gray and Sons Jewelers is what you are looking for.
              Highly qualified team, best service, and best prices only at Gray and Sons Jewelers.
              <br />
              <br />
              <b>The most popular Piaget models of all times: </b>
              <br />
              <br />
              <ul>
                <li>Piaget Gouverneur Manual in Platinum Ref. 15968PT</li>
                <li>Piaget Altiplano Manual in Rose Gold Ref. P10321</li>
                <li>Piaget Emperador Automatic in White Gold Ref. GOA33018</li>
                <li>Piaget Polo Automatic in Stainless Steel Ref. G0A46013</li>
                <li>Piaget Polo S Automatic in Stainless Steel Ref. G0A43002</li>
                <li>Piaget Protocole Chronograph in Yellow Gold Ref. 14254</li>
              </ul>
              <br />
              <br />
              Gray and Sons Jewelers has more than 42 years of experience in the luxury watch and
              jewelry industry. Trusted and qualified team, the best opinions, and best prices. For
              more best-priced Piaget watches, visit us online at www.grayandsons.com and see the
              available used Piaget watches or other luxury watches and jewelry, including Cartier,
              Rolex, Patek Philippe, Audemars Piguet, and much more. Come to Gray and Sons Jewelers
              Showroom at 9595 Harding Ave Surfside, Florida, across from Bal Harbour Shops. Open
              six days a week, Monday to Saturday, with six qualified in-house watch experts
              available for our customers.
              <br />
              <br />
              <b>The #1 Independent watch service in Miami/Bal Harbour</b>
              <br />
              <br />
              Visit <a href="/">www.grayandsons.com. </a> Gray and Sons Jewelers is the #1
              Independent watch service for pre-owned Piaget watches. Looking for watch service,
              repair, or restoration? Gray and Sons Jewelers offers professional luxury watch
              repair, restoration, polishing, and cleaning. Visit Gray and Sons Jewelers - the top
              place to buy, sell, trade, repair, and consign used and pre-owned watches and jewelry,
              including estate, used, pre-owned, vintage, antique, and heirloom watches and jewelry.
              The Gray and Sons Jewelers customers rate Gray and Sons Jewelers as the #1 watch and
              jewelry store in Miami and in the U.S.
              <br />
              <br />
              <b>Sell your Piaget watch and get the best price </b>
              <br />
              <br />
              Do you want to sell your luxury watch and sell your luxury jewelry for the best price?
              Sell Us Your Jewelry is the Gray and Sons Jewelers buying division and the top place
              to get the highest price in the shortest time. Click to visit{' '}
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a> and{' '}
              <a href="/chat-with-rich">Chat with a decision-maker here </a>
              to see our special selling procedure. Our selling procedure is always quick, easy, and
              free of charge.
              <br />
              <br />
              <c>
                WEBSITE: <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a>
                <br />
                CALL: 305-770-6955. <br />
                TEXT: Vika at 786-266-4763 for a quick response. <br />
                EMAIL: sell@grayandsons.com <br /> <br />
                Gray and Sons Jewelers specializes in Pre-Owned, Estate,
                <br />
                Vintage, Antique, Heirloom, Used Watches &anp; Jewelry
                <br />
                <br />
                We Buy, Sell Trade, Consign and Repair Used and Pre-Owned Watches and Jewelry
                <br />
                <br />
                Our customers say; "There are no better certified pre-owned Watch Specialists &amp;
                Jewelers than Gray and Sons Jewelers in all of Florida and US"
                <br />
                <br />
                Visit <a href="/">www.grayandsons.com </a>
              </c>
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/BREITLING/">
                <button>SHOP PIAGET WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PIAGET WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <OmegaCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PiagetMain
